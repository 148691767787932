import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 84,
  height: 52,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(32px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 48,
    height: 48,
  },
  "& .MuiSwitch-track": {
    borderRadius: 52 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const LightSwitch = () => {
  const [on, setOn] = useState(false);
  const handleChange = (event) => {
    fetch(
      `https://home.philipwu.me/light/${event.target.checked ? "on" : "off"}`
    );
    setOn(event.target.checked);
  };

  useEffect(() => {
    fetch("https://home.philipwu.me/light")
      .then((res) => res.json())
      .then((res) => {
        setOn(res.code === 1);
      });
  }, []);

  return (
    <FormControlLabel
      control={<IOSSwitch sx={{ m: 1 }} checked={on} onChange={handleChange} />}
      label={<Typography variant="h5">{on ? "On" : "Off"}</Typography>}
    />
  );
};

export default LightSwitch;
