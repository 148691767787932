import HomeTemprature from "./HomeTemprature";
import LightSwitch from "./LightSwitch";
import Div from "./Div";

function App() {
  // return <HomeTemprature />;
  return (
    <Div
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <LightSwitch />
    </Div>
  );
}

export default App;
